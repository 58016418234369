<template>
    <div class="app-container mini-evaluation-management">
        <div class="button-line">
            <r-button plain @click="addNewConfig">新增</r-button>
            <r-button @click="initDatas">刷新</r-button>
        </div>
        <div>
            <finalTable ref="finalTableRef" :datas="dataset" @tableEventHandler="tableEventHandler" />
        </div>
        <el-dialog :visible.sync="detailDialogShow" width="728px" :destroy-on-close="true"
            :close-on-click-modal="false">
            <template slot="title">
                <span>新增</span>
            </template>
            <warnRules :updateId="updateId" v-if="detailDialogShow" @close="closeDialog" />
        </el-dialog>
    </div>
</template>
<script>
import finalTable from "@/components/FinalTable";
import warnRules from "./warnRules.vue";
import {
    getRulesList,findDelete
} from "@/api/ruge/ams/basicConfig/warnRules";
export default {
    name: "ams-warnRules",
    components: {
        finalTable,
        warnRules
    },
    data() {
        return {
            updateId:null,
            loadingFlag: false,
            detailDialogShow: false,
            ruleObj: {},
            dataset: {
                paginationConfig: {
                    need: true,
                },
                filterLine: {
                    show: true,
                },
                selection: {
                    need: false,
                },
                header: [
                    { prop: "warehouseNameStr", label: "所属仓库", width: "" },
                    { prop: "categoryNameStr", label: "资产类型", width: "" },
                    { prop: "inventoryMax", label: "库存上限", width: "" },
                    { prop: "inventoryMin", label: "库存下限", width: "" },
                    { prop: "inventoryDays", label: "库存天数>=", width: "" },
                    { prop: "operation", label: "操作", width: "150" },
                ],
                tableData: [],
                // 搜索行配置
                searchLineConfig: {
                    warehouseNameStr: {
                        type: "input",
                        label: "所属仓库",
                        value: "",
                        actionType: "goSearch",
                        placeholder: "请输入所属仓库",
                        prefixIcon: "el-icon-search",
                    },
                    categoryNameStr: {
                        type: "input",
                        label: "资产类型",
                        value: "",
                        actionType: "goSearch",
                        placeholder: "请输入资产类型",
                        prefixIcon: "el-icon-search",
                    },
                },
                // 表格内容配置
                detailConfig: {
                    //   creationDate: {
                    //     type: "dateFormat",
                    //   },
                    operation: {
                        type: "icon",
                        iconList: [
                            {
                                actionType: "iconClick",
                                eventName: "edit",
                                fontSize: "14px",
                                color: "#1A4CEC",
                                cursorPointer: "pointer",
                                iconName: "el-icon-edit",
                                tooltips: "编辑",
                            },
                            {
                                actionType: "iconClick",
                                eventName: "delete",
                                fontSize: "14px",
                                color: "#E47470",
                                cursorPointer: "pointer",
                                iconName: "el-icon-delete",
                                tooltips: "删除",
                            },

                        ],
                    },
                },
                // 高级搜索配置
                advanceFilterConfig: {
                    warehouseNameStr: {
                        inline: true,
                        value: "",
                    },
                    categoryNameStr: {
                        inline: true,
                        value: "",
                    },

                },
                pageVO: {
                    current: 1,
                    rowCount: 10,
                    total: 0,
                },
            },
            filterObj: {
                current: 1,
                rowCount: 10,
                warehouseNameStr: "",
                categoryNameStr:""
            },
        };
    },
    watch: {
        loadingFlag: {
            handler(val) {
                this.$refs.finalTableRef.loadingToggle(val);
            },
        },
    },
    created() {
        this.initDatas();
    },
    methods: {
        closeDialog() {
            this.detailDialogShow = false;
            this.initDatas();
        },
        addNewConfig() {
            this.updateId = null
            this.detailDialogShow = true;
        },
        initDatas() {
            this.loadingFlag = true;
            getRulesList(this.filterObj)
                .then((res) => {
                    this.dataset.tableData = res.data.rows;
                    this.dataset.pageVO.total = res.data.totalPages;
                })
                .finally(() => {
                    this.loadingFlag = false;
                });
        },
        tableEventHandler(datas) {
            if (datas.type === "goSearch") {
                this.filterObj.current = 1;
                this.filterObj = { ...this.filterObj, ...datas.params };
                this.initDatas();
            } else if (datas.type === "paginationChange") {
                this.filterObj.current = datas.params.current.page;
                this.filterObj.rowCount = datas.params.current.limit;
                this.initDatas();
            } else if (datas.type === "iconClick") {
                switch (datas.eventName) {
                    case "delete":
                        this.deleteConfig(datas.row);
                        break;
                    case "edit":
                        this.editConfig(datas.row);
                        break;
                }
            }
        },
        editConfig(rowDatas) {
            this.detailDialogShow = true
            this.updateId=rowDatas.warnRuleId
        },
        async deleteConfig(rowDatas) {
            await this.$confirm(`是否确认删除`, this.$t("commons.warning"), {
                confirmButtonText: this.$t("commons.confirm"),
                cancelButtonText: this.$t("commons.cancel"),
                type: "warning",
            });
            findDelete({
                warnRuleId: rowDatas.warnRuleId
            }).then(() => {
                this.$message.success("删除成功！");
                this.initDatas();
            });
        },
    },
};
</script>

<style lang="less" scoped>
.mini-evaluation-management {
    .button-line {
        margin-bottom: 10px;
    }
}
</style>
import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 *  分页查询
 * @param params
 */
export function getRulesList(params) {
  return request({
    url: envInfo.bgApp.amsPath + '/warn/page',
    method: 'get',
    params,
  });
}

/**
 *  新增-查询公司部门下的仓库
 * @param params
 */
export function getWarehouse(params) {
  return request({
    url: envInfo.bgApp.amsPath + '/warehouse/getWarehouseByCompanyDepart',
    method: 'get',
    params,
  });
}

/**
 *  新增-查询资产品类
 * @param params
 */
export function getCategory(params) {
  return request({
    url: envInfo.bgApp.amsPath + '/category/asset/list',
    method: 'get',
    params,
  });
}

/**
 *  新增/编辑
 * @param params
 */
export function addWarn(params) {
  return request({
    url: envInfo.bgApp.amsPath + '/warn/add',
    method: 'post',
    data:params,
  });
}


/**
 *  编辑 --查看详情
 * @param params
 */
export function findWarnDetails(params) {
  return request({
    url: envInfo.bgApp.amsPath + '/warn/detail',
    method: 'get',
    params,
  });
}


/**
 *  s
 * @param params
 */
export function findDelete(params) {
  return request({
    url: envInfo.bgApp.amsPath + '/warn/delete',
    method: 'get',
    params,
  });
}








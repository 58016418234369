<template>
    <div class="guide-form-comp">
        <el-form :model="ruleForm" :rules="rules" label-position="top" ref="ruleForm" label-width="100px"
            class="demo-ruleForm">
            <el-row>
                <el-col>
                    <el-form-item label="所属仓库" prop="warehouseList">
                        <el-cascader v-model="ruleForm.warehouseList" :options="wareData" :props="{ multiple: true }"
                            collapse-tags style="width: 100%" clearable>
                        </el-cascader>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <el-form-item label="资产品类" prop="categoryAssetVOList">
                        <el-select style="width: 100%" v-model="ruleForm.categoryAssetVOList" multiple>
                            <el-option v-for="item in categoryData" :key="item.assetCategoryId"
                                :label="item.assetCategoryName" :value="item.assetCategoryId">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col>
                    <el-form-item label="库存上限" prop="inventoryMax">
                        <el-input placeholder="请输入库存上限" v-model="ruleForm.inventoryMax" maxlength="50"
                            show-word-limit></el-input></el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <el-form-item label="库存下限" prop="inventoryMin">
                        <el-input placeholder="请输入库存下限" v-model="ruleForm.inventoryMin" maxlength="50"
                            show-word-limit></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <el-form-item label="库存天数" prop="inventoryDays">
                        <el-input placeholder="请输入库存天数" v-model="ruleForm.inventoryDays" maxlength="50"
                            show-word-limit></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-form-item align="right">
                    <el-button type="primary" @click="submitForm('ruleForm')" v-preventReClick>确定</el-button>
                    <el-button @click="resetForm">取消</el-button>
                </el-form-item>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import {
    getWarehouse, getCategory, addWarn, findWarnDetails
} from "@/api/ruge/ams/basicConfig/warnRules";
import { listToTree } from "@/utils/tree";
export default {
    props: {
        updateId: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            warnRuleId: "",
            ruleForm: {
                warehouseList: [],
                categoryAssetVOList: [],
                inventoryMax: null,
                inventoryMin: null,
                inventoryDays: null
            },
            rules: {
                warehouseList: [{ required: true, message: "仓库管理员为必填", trigger: "change" },],
                categoryAssetVOList: [{ required: true, message: "资产品类", trigger: "change" },],
                inventoryMax: [
                    { required: true, message: "库存上限为必填", trigger: "blur" },
                ],
                inventoryMin: [
                    { required: true, message: "库存下限为必填", trigger: "blur" },
                ],
                inventoryDays: [
                    { required: true, message: "库存天数为必填", trigger: "blur" },
                ],
            },
            categoryData: [],
            wareData: []
        };
    },
    created() {
        this.findWarehouseList()
        this.findCategory()
        if (this.updateId) {
            this.findDetail()
        }
    },
    methods: {
        findDetail() {//详情
            findWarnDetails({
                warnRuleId: this.updateId
            }).then((res) => {
                if (res.data) {
                    console.log("XXX")
                    this.ruleForm.inventoryMax = res.data.inventoryMax
                    this.ruleForm.inventoryMin = res.data.inventoryMin
                    this.ruleForm.inventoryDays = res.data.inventoryDays
                    this.ruleForm.categoryAssetVOList = res.data.categoryAssetVOList.map((item) => { return item.assetCategoryId })
                    this.ruleForm.warehouseList = res.data.warehouseList.map((item) => {
                        return item.relationStrList
                    })
                }
            })
        },
        findWarehouseList() {
            getWarehouse().then((res) => {
                if (res.data) {
                    let data = listToTree(res.data, "nodeId", "parentNodeId");
                    this.setTree(data)
                    console.log("data", data)
                }
            })
        },
        findCategory() {
            getCategory({
                parentId: 'ROOT'
            }).then((res) => {
                if (res) {
                    this.categoryData = res
                }
            })
        },
        setTree(row) {
            let data = row.map((v) => {
                console.log("v", v)
                v.value = v.nodeId;
                v.label = v.nodeName;
                if (v.children != undefined) {
                    this.setTree(v.children);
                }
                return v;
            })
            this.wareData = data;
            // console.log("v", this.wareData)
        },

        handleChange(value) {
            console.log(value);
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.submitHandler();
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        submitHandler() {
            console.log("this.ruleForm", this.ruleForm)
            let wList = []
            this.wareData.forEach((item) => {
                item.children.forEach((cItem) => {
                    cItem.children.forEach((wItem) => {
                        this.ruleForm.warehouseList.forEach((adItem) => {
                            console.log("adItem", adItem, wItem,)
                            if (wItem.nodeId == adItem[2]) {
                                wList.push({
                                    warehouseId: wItem.warehouseId,
                                    warehouseName: wItem.warehouseName
                                })
                            }
                        })
                    })
                })

            })

            let uList = []
            this.categoryData.forEach((item) => {
                this.ruleForm.categoryAssetVOList.forEach((adItem) => {
                    if (item.assetCategoryId == adItem) {
                        uList.push({
                            assetCategoryId: item.assetCategoryId,
                            assetCategoryName: item.assetCategoryName
                        })
                    }
                })
            })
            console.log("wListxxxxx---", wList,)
            console.log("uList", uList)
            addWarn({
                warnRuleId: this.updateId,
                inventoryMax: this.ruleForm.inventoryMax,
                inventoryMin: this.ruleForm.inventoryMin,
                inventoryDays: this.ruleForm.inventoryDays,
                categoryAssetVOList: uList,
                warehouseList: wList
            }).then((res) => {
                if (res.code === 200) {
                    this.$message.success("操作成功！");
                    this.$emit("close", true);
                } else {
                    this.$message.warning("操作失败！");
                }
            });
        },
        resetForm() {
            this.$emit("close", false);
        },

    },
};
</script>

<style scoped lang="less"></style>